import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { Text } from 'components';
import { useRouter } from 'apis/history';
//@ts-ignore
import third from 'assets/videos/testimonials/lc-1.mp4';
//@ts-ignore
import forth from 'assets/videos/testimonials/lc-2.mp4';
//@ts-ignore
import second from 'assets/videos/testimonials/lc-3.mp4';
//@ts-ignore
import first from 'assets/videos/testimonials/lc-4.mp4';
//@ts-ignore
import Verified from 'assets/icons/isverified.svg';

interface IListItem {
  review?: string;
  name?: string;
}

interface ResultsReviewsVideosProps {
  title?: string;
  list?: IListItem[];
}

const ResultsReviewsVideos: FC<ResultsReviewsVideosProps> = ({
  title,
  list,
}) => {
  const VideoMap = [first, second, third, forth];

  return (
    <>
      <Title dangerouslySetInnerHTML={{ __html: title! }} />

      <ListContainer>
        {list?.map((item, index) => (
          <ListItem key={index}>
            <ImgContainer>
              <StyledVideo
                crossOrigin="anonymous"
                aria-label="Video"
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
                x-webkit-airplay="allow"
                width="100%"
                height="100%"
              >
                <source src={VideoMap[index]} type="video/mp4" />
              </StyledVideo>
            </ImgContainer>
            <TextContainer>
              <Top>
                <ListText>{item.review}</ListText>
              </Top>
              <BottomContainer>
                <Name>{item.name}</Name>
                <Verified />
              </BottomContainer>
            </TextContainer>
          </ListItem>
        ))}
      </ListContainer>
    </>
  );
};

export default ResultsReviewsVideos;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 5rem;
  border-radius: 0.5rem;
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.063rem;
  margin-bottom: 1.5rem;
  span {
    color: #ff875a;
  }
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const TextContainer = styled.section`
  width: 100%;
`;

const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  margin-bottom: 1.5rem;
`;

const ListItem = styled.section`
  display: flex;
  gap: 0.75rem;
  overflow: hidden;
  border-radius: 0.5rem;
  height: 6.875rem;
  width: 100%;
`;

const Top = styled.section`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const ListText = styled(Text)`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled(Text)`
  color: #28293d;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 5rem;
  height: 6.875rem;
  border-radius: 0.5rem;
`;
