import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
//@ts-ignore
import Logo from 'assets/icons/logo.svg';
import ResultsProgress from 'components/ResultsProgress';
import { tablet } from 'styles/breakpoints';
import { Loader, PrimaryButton } from 'components';
import ResultsProgram from './components/ResultsProgram';
import ResultsHabits from './components/ResultsHabits';
import ResultsSummary from './components/ResultsSummary';
import ResultsInfo from './components/ResultsInfo';
import ResultsChart from './components/ResultsChart';
import ResultsAbout from './components/ResultsAbout';
import ResultsFeatured from './components/ResultsFeatured';
import ResultsReview from './components/ResultsReview';
import ResultsReviewsVideos from './components/ResultsReviewsVideos';
import { useRouter } from 'apis/history';
import { useLocation } from '@reach/router';

interface ListItem {
  title?: string;
  text?: string;
  review?: string;
  name?: string;
}

interface ResultsSlide {
  type: string;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  imgDes?: string;
  imgMob?: string;
  with?: string;
  without?: string;
  afterList?: string[];
  afterTitle?: string;
  img?: string;
  nowList?: string[];
  nowTitle?: string;
  boxText?: string;
  infoList?: string[];
  list?: ListItem[];
  first?: string;
  second?: string;
}

enum Slide {
  PROGRAM = 'results-program',
  HABITS = 'results-habits',
  SUMMARY = 'results-summary',
  CHART = 'results-chart',
  INFO = 'results-info',
  ABOUT = 'results-about',
  FEATURED = 'results-featured',
  REVIEW = 'results-review',
  REVIEWS_VIDEOS = 'results-reviews-videos',
}

const Header = memo(
  ({
    currentStep,
    totalSteps,
  }: {
    currentStep: number;
    totalSteps: number;
  }) => (
    <StyledHeader>
      <Logo />
      <ResultsProgress currentStep={currentStep} totalSteps={totalSteps} />
    </StyledHeader>
  ),
);

const SLIDE_COMPONENTS = {
  [Slide.PROGRAM]: ResultsProgram,
  [Slide.HABITS]: ResultsHabits,
  [Slide.SUMMARY]: ResultsSummary,
  [Slide.CHART]: ResultsChart,
  [Slide.INFO]: ResultsInfo,
  [Slide.ABOUT]: ResultsAbout,
  [Slide.FEATURED]: ResultsFeatured,
  [Slide.REVIEW]: ResultsReview,
  [Slide.REVIEWS_VIDEOS]: ResultsReviewsVideos,
};

const updateUrlWithParams = (
  slideParam: string | null,
  existingParams: URLSearchParams,
) => {
  const newParams = new URLSearchParams();

  if (slideParam !== null) {
    newParams.set('s', slideParam);
  }

  existingParams.forEach((value, key) => {
    if (key !== 's') {
      newParams.set(key, value);
    }
  });

  return newParams;
};

const ResultsTemp = () => {
  const user = useSelector((state: AppState) => state.user);
  const data = useQuizData('resultsSlides');
  const slides = data as ResultsSlide[] | null;
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const { goToCheckout } = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    //@ts-ignore
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [currentSlideIndex]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sParam = params.get('s');

    if (!sParam || isNaN(Number(sParam))) {
      return;
    }

    window.scrollTo({ top: 0, behavior: 'instant' });

    setCurrentSlideIndex(Number(sParam) - 1);
  }, [location]);

  useEffect(() => {
    if (!slides?.length) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const param = params.get('s');
    const paramAsNumber = Number(param);

    if (
      isNaN(paramAsNumber) ||
      paramAsNumber < 0 ||
      paramAsNumber > slides.length
    ) {
      const newSlideIndex = 1;

      const params = new URLSearchParams(window.location.search);
      params.set('s', newSlideIndex.toString());
      window.history.pushState(
        { newSlideIndex },
        '',
        `${window.location.pathname}?${params.toString()}`,
      );

      setCurrentSlideIndex(0);
    }

    setCurrentSlideIndex(param ? paramAsNumber - 1 : 0);
  }, [slides]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const param = params.get('s');

    if (!param) {
      const newSlideIndex = 1;

      const params = new URLSearchParams(window.location.search);
      params.set('s', newSlideIndex.toString());
      window.history.replaceState(
        { newSlideIndex },
        '',
        `${window.location.pathname}?${params.toString()}`,
      );

      setCurrentSlideIndex(0);
    }
  }, []);

  const handleContinueClick = () => {
    if (!slides) {
      return;
    }

    if (currentSlideIndex + 1 >= slides?.length!) {
      setLoading(true);
      return goToCheckout({ discount: false });
    }

    const nextSlideIndex = currentSlideIndex + 2;
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = updateUrlWithParams(
      nextSlideIndex.toString(),
      currentParams,
    );

    window.history.pushState(
      { slideIndex: currentSlideIndex + 1 },
      '',
      `${window.location.pathname}?${newParams.toString()}`,
    );

    setCurrentSlideIndex(prev => prev + 1);
  };

  if (!slides?.length || loading) {
    return (
      <Container>
        <StyledHeader>
          <Logo />
        </StyledHeader>
        <Loader />
      </Container>
    );
  }

  const currentSlide = slides[currentSlideIndex];

  if (!currentSlide) {
    setCurrentSlideIndex(0);
    return null;
  }

  const SlideComponent = SLIDE_COMPONENTS[currentSlide.type as Slide];

  return (
    <Container>
      <Header currentStep={currentSlideIndex + 1} totalSteps={slides.length} />
      <Card>
        {SlideComponent && <SlideComponent {...currentSlide} />}
        <StyledButton onClick={handleContinueClick}>
          {currentSlide.buttonText}
        </StyledButton>
      </Card>
    </Container>
  );
};

export default memo(ResultsTemp);

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #ffe7de;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  gap: 1.5rem;

  @media ${tablet} {
    padding: 1.25rem 1rem 2rem;
    gap: 2rem;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1.25rem;
  max-width: 31.25rem;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  width: 100%;
  max-width: 31.25rem;
  height: fit-content;

  transition: height 1000ms ease-in;
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;
