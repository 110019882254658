import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import Check from 'assets/icons/green-checkmark.svg';
import { DynamicImage } from 'components';

interface ResultsInfoProps {
  title?: string;
  subtitle?: string;
  img?: string;
  infoList?: string[];
}

const ResultsInfo: FC<ResultsInfoProps> = ({
  title,
  subtitle,
  img,
  infoList,
}) => (
  <>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <StyledImg src={img!} alt="" />
    <List>
      {infoList?.map((item: string, index: number) => (
        <ListBox>
          <Svg>
            <Check />
          </Svg>
          <ListItem key={index} dangerouslySetInnerHTML={{ __html: item }} />
        </ListBox>
      ))}
    </List>
  </>
);

export default ResultsInfo;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding-top: 0.75rem;
`;

const StyledImg = styled(DynamicImage)`
  margin: 0.8rem auto 1.2rem;
  height: 18.10694rem;
  @media ${tablet} {
    height: 16.5625rem;
    margin: 0 auto 0.5rem;
  }
`;

const List = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ListBox = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 0.5625rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

const Svg = styled.section`
  width: 1.625rem;
  height: 1.625rem;
  svg {
    width: 1.625rem;
    height: 1.625rem;
  }
`;
