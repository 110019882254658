import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
//@ts-ignore
import Chart from 'assets/icons/results-summary.svg';
import { DynamicImage } from 'components';

interface ResultsSummaryProps {
  title?: string;
  subtitle?: string;
  imgMob?: string;
  imgDes?: string;
  boxText?: string;
}

const ResultsSummary: FC<ResultsSummaryProps> = ({
  title,
  subtitle,
  imgMob,
  imgDes,
  boxText,
}) => {
  const { isTablet } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <StyledImg src={isTablet ? imgMob! : imgDes!} alt="" />
      <Box>
        <Chart />
        <BoxText dangerouslySetInnerHTML={{ __html: boxText! }} />
      </Box>
    </>
  );
};

export default ResultsSummary;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;
  margin-bottom: 0.75rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.016rem;
`;

const StyledImg = styled(DynamicImage)`
  margin: 1.5rem auto;
  max-width: 29.25rem;
`;

const Box = styled.section`
  border-radius: 0.5rem;
  background: #fff8e0;
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  margin-bottom: 1.5rem;
`;

const BoxText = styled.p`
  color: #28293d;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.00963rem;
  span {
    font-weight: 700;
  }
`;
