import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
//@ts-ignore
import Verified from 'assets/icons/isverified.svg';
//@ts-ignore
import Full from 'assets/icons/full-trustpilot.svg';
import { DynamicImage } from 'components';

interface ResultsReviewProps {
  title?: string;
  img?: string;
  review?: string;
  name?: string;
}

const ResultsReview: FC<ResultsReviewProps> = ({
  title,
  img,
  review,
  name,
}) => (
  <>
    <Title dangerouslySetInnerHTML={{ __html: title! }} />
    <Img src={img!} alt="" />
    <Full />
    <Review dangerouslySetInnerHTML={{ __html: review! }} />
    <Bottom>
      <Name>{name}</Name>
      <Verified />
    </Bottom>
  </>
);

export default ResultsReview;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.063rem;
  margin-bottom: 1.5rem;
  span {
    color: #ff875a;
  }
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Img = styled(DynamicImage)`
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

const Review = styled.p`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding: 1rem 0;
`;

const Bottom = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const Name = styled.p`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
`;
