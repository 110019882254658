import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton } from 'components';

interface ResultsProgramProps {
  title?: string;
  subtitle?: string;
  imgMob?: string;
  imgDes?: string;
}

const ResultsProgram: FC<ResultsProgramProps> = ({
  title,
  subtitle,
  imgMob,
  imgDes,
}) => {
  const { isTablet } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <Subtitle dangerouslySetInnerHTML={{ __html: subtitle! }} />
      <StyledImg src={isTablet ? imgMob! : imgDes!} alt="" />
    </>
  );
};

export default ResultsProgram;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding-top: 0.75rem;
  span {
    color: #2fbe8f;
    font-weight: 700;
  }
  b {
    font-weight: 700;
  }
`;

const StyledImg = styled(DynamicImage)`
  margin: 1.5rem 0;
`;
