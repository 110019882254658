import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import { getDateLanguageString } from 'utils/dates';

interface ResultsChartProps {
  title?: string;
  subtitle?: string;
  imgMob?: string;
  img?: string;
}

const currentDate = new Date();
const currentMonth = currentDate.toLocaleString(getDateLanguageString(), {
  month: 'long',
});

const nextMonth = new Date();
nextMonth.setMonth(currentDate.getMonth() + 1);

const thirdMonth = new Date();

thirdMonth.setMonth(currentDate.getMonth() + 2);

const ResultsChart: FC<ResultsChartProps> = ({
  title,
  subtitle,
  imgMob,
  img,
}) => {
  const { isTablet, isMobile } = useQuery();

  return (
    <>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ImageWrapper>
        <StyledImg src={isTablet ? imgMob! : img!} alt="" />
        <MonthsLine>
          <MonthLabel>{currentMonth}</MonthLabel>
          <MonthLabel>
            {nextMonth.toLocaleString(getDateLanguageString('en-US'), {
              month: 'long',
            })}
          </MonthLabel>
          <MonthLabel>
            {thirdMonth.toLocaleString(getDateLanguageString('en-US'), {
              month: 'long',
            })}
          </MonthLabel>
        </MonthsLine>
      </ImageWrapper>
    </>
  );
};

export default ResultsChart;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding-top: 0.75rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 29.27956rem;
  height: 17.53369rem;

  @media ${tablet} {
    width: 19.93463rem;
    height: 11.66275rem;
  }
`;

const ImageWrapper = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MonthsLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  max-width: 460px;
  margin-left: 2.5rem;
  width: 24.6rem;

  @media ${tablet} {
    width: 16rem;
    margin-left: 1.7rem;
  }
`;

const MonthLabel = styled.p`
  color: #28293d;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media ${tablet} {
    font-size: 0.75rem;
  }
`;
